.sponsorContainer{
    height: fit-content;
    width: 100vw;
    background-image: url('../assests/sponser.png'), linear-gradient(150deg,#54ddba, #4d114f);
    background-position: center;
    background-size: cover;
    overflow: hidden;
}

.sponsorHeading{
    width: 80vw;
    margin: 50px 0;
}

.abc{
    display: flex;
    justify-content: center;
    align-items: center;
}
.slogo{
    width: 95%;
}
.sponsorImg{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100vw;
}